import React from "react"
import Layout from "../components/layout"

const Reglar = () => {
    return(
        <Layout>
            <h1>Leiðreglar</h1>
        </Layout>
    )
}

export default Reglar